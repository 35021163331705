@tailwind base;
@tailwind components;
@tailwind utilities;

.amplify-tabs { display: none; }

a {
  color: #31374a;
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
