// 
// user-variables.scss
// Use this to override Bootstrap and Finder variables
//

// Example of a variable override to change Finder background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;
//--phoenix-dropdown-inner-border-radius: calc(0.375rem - 1px);

//@include border-top-radius(var(--#{$prefix}dropdown-inner-border-radius));



.jarallax-container {
    position: absolute;
    top: 0;
    left: -400;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: {
      position: center !important;
      size: cover !important;
    }
  }



  

